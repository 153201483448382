import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-services"} />
		<Helmet>
			<title>
				Our Services | VideoSanat Akademisi Hizmetlerimiz
			</title>
			<meta name={"description"} content={"Sanatınızı özgür bırakın – Yaratıcılığınızı keşfetmek için sunduğumuz benzersiz hizmetler"} />
			<meta property={"og:title"} content={"Our Services | VideoSanat Akademisi Hizmetlerimiz"} />
			<meta property={"og:description"} content={"Sanatınızı özgür bırakın – Yaratıcılığınızı keşfetmek için sunduğumuz benzersiz hizmetler"} />
			<meta property={"og:image"} content={"https://vexnubet.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vexnubet.com/img/6979672.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vexnubet.com/img/6979672.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vexnubet.com/img/6979672.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vexnubet.com/img/6979672.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vexnubet.com/img/6979672.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vexnubet.com/img/6979672.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Hizmet Öne Çıkanlarımız
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					width="80%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					lg-width="100%"
					sm-text-align="left"
				>
					VideoSanat Akademisi'nde, her biri sanatsal vizyonunuzu genişletmek ve teknik becerilerinizi artırmak için tasarlanmış çeşitli hizmetler sunuyoruz. Profesyonel video düzenleme ve prodüksiyon eğitimlerinden, gerçek dünya projelerine kadar, her adımda yanınızdayız.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
					Eğitim Programlarımız
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Temel Video Düzenleme: Video düzenleme sanatının temellerini öğrenin ve hikayelerinizi anlatmaya başlayın.
						<br />
						<br />
İleri Prodüksiyon Teknikleri: Çekim tekniklerinden renk düzenlemeye kadar ileri seviye prodüksiyon becerileri kazanın.
						<br />
						<br />
Yaratıcı İçerik Üretimi: Yaratıcılığınızı teşvik eden projelerle kendi özgün içeriklerinizi oluşturun.
						
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Serviços adicionais
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						- Sistemas de navegação GPS: Disponível em todos os veículos para o ajudar a encontrar o seu caminho sem esforço.
						<br />
						<br />
						- Bancos de segurança para crianças: Garantem a segurança dos mais pequenos durante a viagem.
						<br />
						<br />
						- Assistência na estrada 24 horas por dia, 7 dias por semana: Paz de espírito sabendo que a ajuda está apenas a um telefonema de distância, independentemente do local onde se encontre.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Öne Çıkanlarımız
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Uzman Eğitmenler: Alanında uzman eğitmenler tarafından sunulan kapsamlı eğitimler.
						<br />
Esnek Öğrenme Modelleri: İhtiyaçlarınıza göre tasarlanmış canlı dersler ve çevrimiçi materyaller.
						<br />
Gerçek Dünya Deneyimi: Sektör standartlarına uygun projelerle gerçek dünya tecrübesi kazanın.
						<br />
Yaratıcı Topluluk: Benzer düşünen yaratıcılarla birlikte büyüyün ve ilham alın.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Bizimle Yaratıcılığınızı Geliştirin
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					VideoSanat Akademisi, kendi hikayenizi anlatma yeteneğinizi geliştirme şansı sunar. Burada, teknik becerilerinizi artırırken aynı zamanda yaratıcı potansiyelinizi de keşfedeceksiniz. Sanatınızla etki yaratmak istiyorsanız, doğru yerdesiniz.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://vexnubet.com/img/5.jpg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://vexnubet.com/img/6.jpg"
					/>
				</Box>
				<Text
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					md-text-align="left"
				>
					Hizmetlerimiz hakkında daha fazla bilgi almak veya eğitim programlarımıza kaydolmak için bizimle iletişime geçin. Yaratıcı yolculuğunuzda sizi desteklemek için buradayız.
				</Text>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://vexnubet.com/img/7.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					VideoSanat Akademisi – Yaratıcılığınızı Sonuna Kadar Yaşayacağınız Yer
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});